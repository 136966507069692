<template>
<div class="">
  <!-- <idUser></idUser> -->
  
  <section class="section-container">
    <v-row class="signin">
      <v-col cols="12" xs="12" md="8" class="xleft">
        <!-- <v-img src="@/assets/logopwa.png" max-width="125" max-height="125"></v-img>     -->
        <v-img src="@/assets/LogoTranserviLtda.png" max-width="262" max-height="48"></v-img>    
      </v-col>
      <v-col cols="12" xs="12" md="4" class="xright">
        <h2>LOGIN</h2>
          <v-form @submit.prevent="submit">
            <v-text-field v-model="contrato" label="Contrato" 
                required 
                outlined 
                dark 
                filled 
                dense
                disabled
            ></v-text-field>
            <v-text-field v-model="login" label="Usuario" 
                required 
                outlined 
                dark 
                filled 
                dense
            ></v-text-field>
            <v-text-field v-model="password" label="Password" 
                :append-icon="showPass ? 'visibility' : 'visibility_off'"
                @click:append="showPass = !showPass"
                required
                outlined
                dense
                dark
                filled
                :type="showPass ? 'text' : 'password'"
            ></v-text-field>
            <div class="text-center">
                <v-btn class="signin-btn" type="submit" rounded color="white" dark>Ingresar</v-btn>
            </div>
          </v-form>
          <div>
            <v-chip class="mt-10" small dark @click="OpenDialog()">Cambiar Password</v-chip>
          </div>
      </v-col>
    </v-row>
  </section>
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="headline">Cambiar Password</v-card-title>
        <v-card-text>
          <v-form>
            <v-text-field v-model="login" label="Usuario" 
                required 
                outlined 
                filled 
                dense
                disabled
            ></v-text-field>
            <v-text-field v-model="password1" label="New Password" 
                :append-icon="showPass ? 'visibility' : 'visibility_off'"
                @click:append="showPass = !showPass"
                required
                outlined
                dense
                filled
                :type="showPass ? 'text' : 'password'"
            ></v-text-field>
            <v-text-field v-model="password2" label="Confirmar" 
                :append-icon="showPass ? 'visibility' : 'visibility_off'"
                @click:append="showPass = !showPass"
                required
                outlined
                dense
                filled
                :type="showPass ? 'text' : 'password'"
            ></v-text-field>
            <div class="text-center">
                <v-btn class="signin-btn" rounded color="teal" dark @click='CambiaPassword()'>Cambiar</v-btn>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialog=false" color="red" small dark><v-icon small>close</v-icon>Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
   <v-snackbar v-model="msgbox" :timeout="timeout" :color="color">
    {{Msg}}
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="msgbox = false">Close</v-btn>
    </template>
  </v-snackbar>
</div>
</template>

<script>
import axios from 'axios';
export default {
  //components:{ idUser },
  data: () => ({
    contrato:'',
    login: '',
    password: null,
    showPass: false,
    msgbox:false,
    timeout: 3000,
    Msg:'',
    color:'',
    dialog:false,
    password1:'',
    password2:'',   
  }),
  mounted(){
    this.inicializar()
  },
  computed: {
    params() {
      return {
        contrato:this.contrato.toUpperCase(),
        login: this.login.toUpperCase(),
        password: this.password
      }
    }
  },
  methods: {
    inicializar(){
    // fijar contrato
    //this.contrato='INV-1008-12345'; // Pruebas 
    this.contrato='INV-1008-40108'; // Produccion
    this.login= '';
    this.password= null;
    this.showPass= false;
    this.msgbox= false;
    this.Msg='';
    this.color='';
    this.dialog= false,
    this.password1= '',
    this.password2= ''   
    },
    async submit() {
      if(this.login!='' & this.password!=null){
        // const response = await axios.get('http://www.transervi.cl/Api_TranServi/public/api/contrato/'+ this.contrato);
        // const datos=response.data;
        const datos='ok';
        if (datos=="ok") {
          const response1 = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/contrato/'+ this.contrato.toUpperCase()+'/'+ this.login.toUpperCase() +'/'+this.password);
          const datos1=response1.data;
          //console.log(datos1);
          if (datos1=="ok") {
            if(this.password=='123' || this.password=='123456'){
              this.Msg='Debe Cambiar Clave'
              this.msgbox=true;
              this.color='orange'              
            } else {
              this.$router.push({name:'Home',params:{idUsuario:this.login.toUpperCase(),idContrato:this.contrato.toUpperCase()}})
            }
          } else {
            this.Msg='Usuario invalido o bloqueado'
            this.msgbox=true;
            this.color='red'
          }          
        } else {
          this.Msg='Contrato Incorrecto'
          this.msgbox=true;
          this.color='red'
        }
      }
    },
    OpenDialog(){
      if (this.contrato!='' && this.login!=''){
        this.password='';
        this.password1='';
        this.password2='';
        this.dialog=true
      } 
      else {
        this.Msg='ingrese Contrato y Usuario'
        this.msgbox=true;
        this.color='red'
      }
    },
    async CambiaPassword(){
      if(this.password1=='123' || this.password1=='123456'){
        this.Msg='Clave invalida'
        this.msgbox=true;
        this.color='red'
      }
      else {
        if((this.password1.length!=0 || this.password2.length!=0)){
          if(this.password1==this.password2){
            //verificamos la existencia del login
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/usuarios/'+this.contrato.toUpperCase()+'/'+ this.login.toUpperCase());
            const datos=response.data;
            if (datos[0].existe=='si'){
              if(datos[0].Contrato==this.contrato.toUpperCase()){
                //modificamos clave
                const response0=await axios.put('https://www.transervi.cl/Api_TranServi/public/api/inv/login/password/'+this.contrato.toUpperCase()+'/'+ this.login.toUpperCase(),{'pass':this.password1});
                const valido=response0.data;
                if(valido=='si'){
                  this.$router.push({name:'Home',params:{idUsuario:this.login.toUpperCase(),idContrato:this.contrato.toUpperCase()}})
                } else {
                  this.Msg='Sin Cambios, intentelo mas tarde'
                  this.msgbox=true;
                  this.color='red'
                }
              } else {
                this.Msg='Contrato invalido'
                this.msgbox=true;
                this.color='red'
              }
            } else {
              this.Msg='Usuario invalido'
              this.msgbox=true;
              this.color='red'
            }
          } else {
            this.Msg='Claves no coinciden'
            this.msgbox=true;
            this.color='red'
          }
        } else {
            this.Msg='Ingrese Claves'
            this.msgbox=true;
            this.color='red'
        }
      }
    },
    clear() {
      // you can use this method to clear login form
      this.email = ''
      this.password = null
      this.$refs.observer.reset()
    }
  }
}
</script>

